jQuery(document).ready(function ($) {
    consoleMessage: {
        console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
        if (!Modernizr.objectfit) {
            $('.object-fit').each(function () {
                var $container = $(this),
                    imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('not-compatible');
                }
            });
        }
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}

	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}

	mobile: {
		///MOBILE MENU - FOR USE WITH TWK_NAV_WALKER
		/* $('.menu .menu-item-has-children > a').click(function (event) {
			event.preventDefault();
			$(this).parent().children('li .sub-menu-wrap').addClass('transform-none');
		});
		$('.back-menu').click(function () {
			$(this).parent().parent().removeClass('transform-none');
		}); */
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () { 
			$(this).addClass('email-link');
		});	 
		
//		$('a:not(.magnific-video, .email-link)').each(function () {
//			var a = new RegExp('/' + window.location.host + '/');
//			
//			if (!a.test(this.href)) {
//				$(this).click(function (event) {
//				event.preventDefault();
//				window.open(this.href, '_blank');
//				});
//			}
//		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}
    
    menuAnimation: {
        const menuOpenTL = gsap.timeline({
            paused: true,
        });
        const menuCloseTL = gsap.timeline({
            paused: true,
        });

     
        menuOpenTL.fromTo('.full-page-menu', 0.5, { autoAlpha: 0 }, { autoAlpha: 1 }).staggerFromTo('.full-page-menu .menu li', 0.5, { opacity: 0, y: '90px' }, { opacity: 1, y: 0 }, 0.1, '-=0.3' ).fromTo('.full-page-menu .social-media', 0.5, { autoAlpha: 0 }, { autoAlpha: 1 });
        
        menuCloseTL.to('.full-page-menu', { duration: 0.4, autoAlpha: 0 });
                
        
        $('.hamburger').on('click', function () {
            if ($(this).hasClass('is-active')) {
                menuCloseTL.play(0);
                 $('.cursor-follow').removeClass('cursor-follow--white');
            } else {
                menuOpenTL.play(0);                
 $('.cursor-follow').addClass('cursor-follow--white');
            }
            $(this).toggleClass('is-active');


        });
        
        
        

    }
    
    cursorAnimations: {
        
         if ($('.cursor-follow').length > 0) {


            $(document).on('mousemove', (event) => {
                TweenMax.to('.cursor-follow', {
                    duration: 0.3,
                    x: event.clientX,
                    y: event.clientY
                });
              
            });
            

            
            $(document).on("mouseenter", '.casestudy-teaser', function() {
                $('.cursor-follow').addClass('view-website');
            });
             $(document).on("mouseleave", '.casestudy-teaser', function() {
                $('.cursor-follow').removeClass('view-website');
            });
            $(document).on("mouseenter", '.js-cursor-interact, a, .slick-arrow', function() {
$('.cursor-follow').addClass('cursor-active');
            });

$(document).on("mouseleave", '.js-cursor-interact, a, .slick-arrow', function() {
$('.cursor-follow').removeClass('cursor-active');
});
            
             
             $(document).on("mouseenter", '.cursor-white, .circle-cta', function() {
                $('.cursor-follow').addClass('cursor-follow--white');
            });
             $(document).on("mouseleave", '.cursor-white, .circle-cta', function() {
                $('.cursor-follow').removeClass('cursor-follow--white');
            });
           
        }
        
            gsap.to('.cursor-follow__rotate', { duration: 15, ease:Linear.easeNone, rotation: 360, repeat: -1});
        
    }
    
    scrollAnimationsIn: {
        
                if($(".simple-banner").length > 0){
            
             $('.simple-banner').each(function(){
            
                let trigger = $(this);
		          let label = $(this).find('.label');
		          let title = $(this).find('.title');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(label, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, 'start' )
                .fromTo(title, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, '-=0.2' );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: false,
            });
    
            
        });
            
        }
        
        if($(".js-title-and-points").length > 0){
            
             $('.js-title-and-points').each(function(){
            
                let trigger = $(this);
		          let title = $(this).find('.js-title');
		          let intro = $(this).find('.js-intro');
		          let points = $(this).find('.js-points .col');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(title, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, 'start' )
                .fromTo(intro, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, '-=0.2' )
                .fromTo(points, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", stagger: { each: '0.3' } }, '-=0.2' );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: false,
            });
    
            
        });
            
        }
        
        if($(".page-teasers").length > 0){
            
             $('.page-teasers').each(function(){
            
                let trigger = $(this);
		       
		          let teasers = $(this).find('.col');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(teasers, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", stagger: { each: '0.3' } } );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: false,
            });
    
            
        });
            
        }
        
        if($(".mouse-scroll-slider").length > 0){
            
             $('.mouse-scroll-slider').each(function(){
            
                let trigger = $(this);
		       
		          let images = $(this).find('.col-auto');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(images, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", stagger: { each: '0.3' } } );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: false,
            });
    
            
        });
            
        }
        
        if($(".js-map").length > 0){
            
             $('.js-map').each(function(){
            
                let trigger = $(this);
		       
		          let title = $(this).find('.js-title');
		          let mapImg = $(this).find('.js-map-img');
		          let pins = $(this).find('.js-pin');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(title, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, 'start' )
                .fromTo(mapImg, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", }, 'start', '-=0.2' )
                .fromTo(pins, { opacity: 0 }, {duration: 1, opacity: 1, ease: "power2.inOut", stagger: { each: '0.3' } }, '-=0.2' );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: false,
            });
    
            
        });
            
        }
        
    }
    
    scrollAnimations: {
        
//        if($(".image-block-teaser__image, .image-block-teaser__block, .quote-block-teaser__quote, .quote-block-teaser__block").length > 0){
//        
//        ScrollTrigger.create({
//            markers: true,
//  trigger: document.body,
//  onUpdate: (self) => {
//    let skewVal = - self.getVelocity() / 200;
//      if(skewVal > 10){
//          skewVal = 10;
//      }
//      if(skewVal < -10){
//          skewVal = -10;
//      }
//    
//    gsap.to(".image-block-teaser__image, .image-block-teaser__block, .quote-block-teaser__quote, .quote-block-teaser__block", {rotationX: skewVal, duration: 0, ease: "power2.out", overwrite: 'auto', onComplete: () => {
//      gsap.to(".image-block-teaser__image, .image-block-teaser__block, .quote-block-teaser__quote, .quote-block-teaser__block", {rotationX: 0, duration: 0.85, ease: "power2.out", overwrite: 'auto'});
//    }});
//  }
//});
//        }
        
        
        if($(".image-block-teaser__image").length > 0){
            
             $('.image-block-teaser__image').each(function(){
            
                let trigger = $(this);
		          let element = $(this).find('img');
		          let block = $(this).parent().find('.image-block-teaser__block');
	

                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = mouseScrollSliderTL
                .fromTo(element, { yPercent: '0', scale: 1.1, }, {duration: 1, yPercent: '-20', scale: 1, ease: "none", }, 'start' )
                .fromTo(block, { y: '30px' }, {duration: 1, y: '-120px', ease: "none", }, 'start' );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: true,
//				markers: true,
            });
            
            
            
        });
            
        }
        
        if($('.portfolio-grid__bg').length > 0){
            let element = $(this).find('.portfolio-grid__bg');
                             let portfolioBG_TL = gsap.timeline({ });

            let animation = portfolioBG_TL
            .fromTo(element, { autoAlpha: 0, }, {duration: 0.4, autoAlpha: 1, ease: "none", } )
            .fromTo(element, { autoAlpha: 1, }, {duration: 0.4, autoAlpha: 0, ease: "none", }, '+=4' )
            		ScrollTrigger.create({
                        animation: animation,
			trigger: '.portfolio-grid',
//			markers: true,
			start: "top center",
			end: "bottom center",
            scrub: true,
//			onEnter: () => gsap.to($('.portfolio-grid__bg'), {autoAlpha: 1, duration: 0.3}),
//			onEnterBack: () => gsap.to($('.portfolio-grid__bg'), {autoAlpha: 1, duration: 0.3}),
//			onLeave: () => gsap.to($('.portfolio-grid__bg'), {autoAlpha: 0, duration: 0.3}),
			onLeaveBack: () => gsap.to($('.portfolio-grid__bg'), {autoAlpha: 0, duration: 0.3}),
			//  toggleClass: {targets: $that, className: "visible"}  
		});
           
           }
        
        $('.mouse-scroll-slider').each(function(){
            
            	let trigger = $(this);
		let element = $(this).find('.mouse-scroll-slider__track');
	   let movement = -50;
if($(trigger).hasClass('mouse-scroll-slider--to-right')){
    movement = movement * -1;
}
                 let mouseScrollSliderTL = gsap.timeline({ });
                let animation = gsap.to(element, {duration: 1, xPercent: movement, ease: "none", } );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: true,
//				markers: true,
            });
            
            
            
        });
        
        $('.contact-video').each(function(){
            
            	let trigger = $(this);
                let mouseScrollSliderTL = gsap.timeline({ });
                let animation = gsap.to(trigger, {duration: 1, yPercent: -100, ease: "none", } );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top center",
                end: "bottom top",
				scrub: true,
//				markers: true,
            });
            
            
            
        });
        
        $('.contact-form').each(function(){
            
            	let trigger = $(this);
                let mouseScrollSliderTL = gsap.timeline({ });
                let animation = gsap.to(trigger, {duration: 1, yPercent: -50, ease: "none", } );

            ScrollTrigger.create({
                animation: animation,
                trigger: trigger,
//		toggleActions: "restart reset restart reset",
				start: "top bottom",
                end: "bottom top",
				scrub: true,
//				markers: true,
            });
            
            
            
        });
        
    }
    
    casestudyHover: {
//         $('.casestudy-teaser').each(function(){
////            let cta_TL = gsap.timeline({ });
////            cta_TL.to($(this).find('.circle-cta__circle'), { duration: 15, ease:Linear.easeNone, rotation: 360, repeat: -1});
////            
////            $(this).on('mouseenter', function(){
////                cta_TL.pause();
////                $(this).addClass('active');
////                
////            });
////            $(this).on('mouseleave', function(){
////                cta_TL.play();
////                $(this).removeClass('active');
////            });
//             
//             var imgs = $.makeArray( $(this).find('img') );
//    imgs.reverse();
//
//function crossfade(){
//    TweenMax.to(imgs[0], 0.05, {autoAlpha:0,}) 
//    TweenMax.to(imgs[1], 0.05, {autoAlpha:1,})
//    imgs.push( imgs.shift() )
//  }
//
//let cycle = null;
//             
//        if(imgs.length > 1){
//        
//            $(this).on('mouseenter', function(){
//               cycle = setInterval(crossfade,400)       
//            });
//            $(this).on('mouseleave', function(){
//               clearInterval(cycle);
//            });
//        }
//         
//         
//         })
        
        
    }
    
    circleCTAs: {
        $('.circle-cta').each(function(){
            let cta_TL = gsap.timeline({ });
            cta_TL.to($(this).find('.circle-cta__circle'), { duration: 15, ease:Linear.easeNone, rotation: 360, repeat: -1});
            
            $(this).on('mouseenter', function(){
                cta_TL.pause();
                $(this).addClass('active');
                
            });
            $(this).on('mouseleave', function(){
                cta_TL.play();
                $(this).removeClass('active');
            });
        });
//        rotationExample = TweenMax.to($('.circle'), 15, {
//        css:
//        {
//            rotation:360
//        },
//        // remove default easing for seamless loop
//        ease:Linear.easeNone
//        repeat: -1,
//        paused: false
//});
    }
    
    pinAnimation: {
        $('.js-pin svg').each(function(){
            let pin_TL = gsap.timeline({ });
            let pulse = $(this).find('.pulse');
            pin_TL.to($(this).find('.pulse'), { duration: 2, transformOrigin: 'center', ease:Linear.easeOut, scale: 4, opacity: 0, stagger: { each: 0.7 }, repeat: -1});
            
        })
        
    }
    
    formAnimations: {
        // Label animation
		$('.gfield input:not([type="submit"]):not([type="file"]):not([type="hidden"]), .gfield select').each(function(){
            var label = $(this).parent().prev();
            var text_val = $(this).val();
            if(text_val !== "") label.addClass('js-active-state');
        });

        $('.gfield input:not([type="submit"]):not([type="file"]):not([type="hidden"]), .gfield select').focusin(function(){
            $(this).parent().prev().addClass('js-active-state');
        });

        $('.gfield input:not([type="submit"]):not([type="file"]):not([type="hidden"]), .gfield select').focusout(function(){
            var label = $(this).parent().prev();

            var text_val = $(this).val();
            if(text_val === "") label.removeClass('js-active-state');
        });


        $('ul.gform_fields').find('textarea').parent().siblings('label').addClass('js-no-animation');
		$('.ginput_container_checkbox, .ginput_container_radio, .ginput_complex').siblings('label').addClass('js-no-animation');


		// Textarea - the label disappears on scroll and goes back when at the top
		$( '.gform_body textarea' ).on( 'scroll', function(){
			$(this).parent().parent().find('label').css('display', 'none');

			
			if ( $(this).scrollTop() <= 12  ) {
				$(this).parent().parent().find('label').css('display', '');
			}
			
		} );
	}
    

	slider: {
        
        $('.instagram__slider').slick({
			dots: false,
            arrows: false,
            slidesToShow: 3,
            swipeToSlide: true,
              responsive: [{
breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
                  
      }],
//			autoplay: true,
//			autoplaySpeed: 4500,
		});
        
        $('.full-width-slider').slick({
			dots: true,
            arrows: false,
            fade: true,
			autoplay: true,
			autoplaySpeed: 4500,
		});


		/**
		 * Gallery slider with thumbnails navigation.
		 */
		var status = $('.gallery-slider__full-nav .pages');
        var slickElement = $('.gallery-slider__full');

        slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            var i = (currentSlide ? currentSlide : 0) + 1;
            status.text(i + ' of ' + slick.slideCount);
		});
		
		$('.gallery-slider__full').slick({
            dots: false,
            arrows: true,
            fade: true,
            asNavFor: '.gallery-slider__thumb',
            appendArrows: '.gallery-slider__full-nav',
        });
        $('.gallery-slider__thumb').slick({
            dots: false,
            arrows: true,
            slidesToShow: 4,
            // centerMode: true,
            focusOnSelect: true,
            asNavFor: '.gallery-slider__full',
        });
	}
    
    videoSlider: {
        
//        js-video-slider-control
        
        		$('.video-slider__slider').slick({
			dots: false,
            arrows: false,
            fade: true,
			autoplay: false,
			autoplaySpeed: 4500,
		});
        
        $('.js-video-slider-control').on('click', function(){
            $('.js-video-slider-control').removeClass('active');
            $(this).addClass('active');
            $('.video-slider__slider').slick('slickGoTo', $(this).data('go-to'));
        });
        
    }

	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				patterns: {
				youtu: {
					index: 'youtu.be',
					id: function( url ) {
					
						// Capture everything after the hostname, excluding possible querystrings.
						var m = url.match( /^.+youtu.be\/([^?]+)/ );
				
						if ( null !== m ) {
							return m[1];
						}
				
						return null;
			
					},
					// Use the captured video ID in an embed URL. 
					// Add/remove querystrings as desired.
					src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
				}
				}
			}
		});

		// INLINE
		$('.inline-popup').magnificPopup({
            type: 'inline',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,
            fixedContentPos: false
        });

        // GALLERY IMAGE
        $('.magnific-gallery-image').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            gallery:{
                enabled:true
            }
		});
	}

	accordion: {
		if ($('.block--accordion').length) {

			$( '.accordion__content--wrapper' ).css('display', 'none'); // All items closed.
	
			$('.block--accordion').find('.js-accordion-trigger').click(function() {
				$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				$('.js-accordion-trigger').not(this).removeClass('open');    // reset
				
				if ($(this).hasClass('open')){
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}
				
			});
		}
	}

	/* tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if ( tabs ) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				tab.addEventListener("click", changeTabs);
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if ( tabList ) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
					tabs[tabFocus].setAttribute("tabindex", -1);
					if (e.keyCode === 39) {
						tabFocus++;
						// If we're at the end, go to the start
						if (tabFocus >= tabs.length) {
						tabFocus = 0;
						}
						// Move left
					} else if (e.keyCode === 37) {
						tabFocus--;
						// If we're at the start, move to the end
						if (tabFocus < 0) {
						tabFocus = tabs.length - 1;
						}
					}
	
					tabs[tabFocus].setAttribute("tabindex", 0);
					tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				console.info('tabs clicked');
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');


					console.info(grandparent);
				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));

				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");
			}

		}
	} */

	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;
		
			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();
		
			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});


